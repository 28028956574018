'use client'

import { useEffect, useRef } from 'react'

import { useFetch } from '@marketplace-web/shared/api-client'
import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { getVasEntryPoints } from 'data/api'
import { transformVasEntryPointsResponse } from 'data/api/transformers/response/transform-vas-entry-points-response'

import { VasEntryPoint } from '../constants/vas-entry-point'

const useFetchVasEntryPoints = (entryPointNames: Array<VasEntryPoint>, isDisabled?: boolean) => {
  const {
    fetch: fetchVasEntryPoints,
    transformedData: data,
    isLoading,
    error,
  } = useFetch(getVasEntryPoints, transformVasEntryPointsResponse)
  const isClosetPromotionKillswitchEnabled = useFeatureSwitch('killswitch_closet_promotion_web')

  const filteredEntryPointNamesRef = useRef<Array<VasEntryPoint>>(
    isClosetPromotionKillswitchEnabled
      ? entryPointNames.filter(entryPoint => entryPoint !== VasEntryPoint.PromotedClosets)
      : entryPointNames,
  )

  useEffect(() => {
    if (!filteredEntryPointNamesRef.current.length) return
    if (isDisabled) return

    fetchVasEntryPoints({ bannerNames: filteredEntryPointNamesRef.current })
  }, [fetchVasEntryPoints, isDisabled, filteredEntryPointNamesRef])

  return { data, isLoading, error }
}

export default useFetchVasEntryPoints
