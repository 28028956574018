import { navigateToPage, setLocalStorageItem } from '@marketplace-web/shared/browser'
import { CheckoutLoadTimeTrackingDetails } from 'types/tracking'
import {
  CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
  CREDIT_CARD_ADD_MODAL_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
  PAYMENT_OPTIONS_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
  SINGLE_CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
} from 'constants/checkout'
import { SINGLE_CHECKOUT_URL } from 'constants/routes'

export const markCheckoutLoadStartTime = ({ itemId }: { itemId: number }) => {
  const loadTimeStartStamp = new Date().getTime()
  const trackingDetails: CheckoutLoadTimeTrackingDetails = {
    itemId,
    loadTimeStartStamp,
  }

  setLocalStorageItem(
    CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
    JSON.stringify(trackingDetails),
  )
}

export const markPaymentOptionsLoadStartTime = () => {
  const loadTimeStartStamp = new Date().getTime()
  const trackingDetails = {
    loadTimeStartStamp,
  }

  setLocalStorageItem(
    PAYMENT_OPTIONS_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
    JSON.stringify(trackingDetails),
  )
}

export const markSingleCheckoutLoadStartTime = (orderId: number) => {
  const loadTimeStartStamp = new Date().getTime()
  const trackingDetails = {
    orderId,
    loadTimeStartStamp,
  }

  setLocalStorageItem(
    SINGLE_CHECKOUT_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
    JSON.stringify(trackingDetails),
  )
}

export const markCreditCardAddModalLoadStartTime = () => {
  const loadTimeStartStamp = new Date().getTime()

  setLocalStorageItem(
    CREDIT_CARD_ADD_MODAL_LOAD_TIME_TRACKING_DETAILS_STORAGE_KEY,
    JSON.stringify({ loadTimeStartStamp }),
  )
}

export const navigateToSingleCheckout = (
  checkoutId: string,
  orderId: number,
  orderType: string,
) => {
  markSingleCheckoutLoadStartTime(orderId)
  navigateToPage(SINGLE_CHECKOUT_URL(checkoutId, orderId, orderType))
}
